/* General styles that apply to both sections */
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Noto+Sans+Tamil&family=Nunito&display=swap");
.section {
  padding: 50px 0;
  font-family: "Lato", sans-serif;
}

.section-title {
  color: #333;
  font-size: 2rem;
  margin-bottom: 0.5em;
}

.section-description {
  color: #555;
  font-size: 1.1rem;
  margin-bottom: 1em;
}

.container {
  max-width: 1140px;
  margin: auto;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Specific styles for the Squad Goals section */
.who-we-are-for {
  background-color: #f9ebc6; /* A light gray that pairs well with the blue sky */
}

.tx-50 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
/* Specific styles for the Team Laanaa section */
.who-we-are {
  background-color: #f5f5f5; /* A slightly different shade of gray for contrast */
}

.image-content {
  flex-basis: 40%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}
.squad_dv {
  width: 68%;
  text-align: left;
}
.squad_dv img {
  width: 50%;
}
.squad_dv p {
  text-align: left;
  font-weight: 500;
  color: #2a262a;
}
.squd_width{
  width: 100%;
  display: flex;
}
.antfont {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 46px;
  color: #544013;
  text-align: left;
}
.tx-50 p {
  text-align: left;
  color: #544013;
  font-weight: 600;
}
/* Specific image backgrounds for each section */
.image-content-whowearefor {
  background-image: url("./images/helping_people.png"); /* Replace with the actual path to your image */
}

.image-content-whoweare {
  background-image: url("./images/WhoWeAre.webp"); /* Replace with the actual path to your image */
}

.lanateamimg {
  background-image: url("../../images/lanateam.jpg");
  width: 180px;
  height: 600;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-position: 100% 100%;
  background-size: cover;
}
.lanateam_sec{
  background: #fff9e9;
}

.lanateam_sec h2{
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #544013;
}

.exp-title{
  font-size: 2.8rem;
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #544013;
  margin: 0 auto 16px;
}

.projects{
  background: #f9ebc6 !important;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .image-content {
    width: 100%;
    order: -1; /* Image comes first */
  }
}

/* Link styles */
.learn-more-link {
  color: #007bff;
  text-decoration: none;
}

.learn-more-link:hover {
  text-decoration: underline;
}
