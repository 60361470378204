@import url("https://fonts.googleapis.com/css2?family=Anton&family=Noto+Sans+Tamil&family=Nunito&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'times new roman';
    src: local('times new roman'), url('./fonts/times-new-roman.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
  }
  .dialog_box .project-card{
    background: #fff;
    border: none;
    width: 100%;
  }
  .dialog_box .project_flex{
    height: auto;
  }
  .greenwave{
    width: 70%;
    display: table;
    margin: 0 auto;
    padding: 30px 0;
  }
  .img_box{
    width: 120px;
    height: 120px;
    background: url(./images/logo.png);
    background-size: cover;
    margin: 0 auto;
  }
  .font-bold{
    font-weight: 700;
  }
  .hr_btn{
    background: none;
    border: 0;
    border-bottom: 1px solid;
    color: blue;
  }
  .mrq_container{
    width: 80% !important;
  }
  .link_col{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #ffb902;
    display: flex;
    justify-content: center;
    /* column-gap: 28px; */
    align-items: center;
  }
  .lana_ttl{
    font-size: 30px;
    color: #fff;
  }
  .banner_logo{
    position: absolute;
    top: 8%;
    left: 4%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    background: url(./images/logo.png);
    background-size: cover;
  }
.linkedin{
    color: #0077B5 !important;
}

.instagram{
    color: #d62976 !important;
}
.flagship{
    background-color: #f9ebc6;
}
.d-flex-lana{
    display: flex;
    justify-content: space-between;
    column-gap: 135px;
}
.d-flex-lana .column{
    flex-direction: column;
    width: 80%;
}
.flagbox{
    background-color: #55463f;
    border-radius: 10px;
    padding: 20px 15px;
    margin: 3px 0;
    height: 140px;
}
.flagbox p{
    font-size: 15px;
}
.flagIcon{
    display: flex;
    align-items: center;
    width: 50%;
    position: relative;
}
.flagIcon h5{
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 38px;
    color: #3e3029;
}
.icontopleft{
    position: absolute !important;
    top: 38px;
}
.icontopright{
    position: absolute !important;
    top: 38px;
    right: 0;
}
.iconbottomleft{
    position: absolute !important;
    bottom: 38px;
}
.iconbottomright{
    position: absolute !important;
    bottom: 38px;
    right: 0;
}
/* whyus section */

.whyus{
    background-color: #3e3029;
    padding: 70px 0;
}
.why_flex{
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.lft_content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    z-index: 2;
}

.lft_content p{
    font-size: 16px;
    background-color: #f9ebc6;
    color: #211d26;
    padding: 20px;
}
.lft_content::before{
    content: '';
    position: absolute;
    top: -8px;
    left: 11px;
    width: 100%;
    height: 142px;
    background: #9b734f;
    z-index: -1;
}
.rytchdv{
    position: relative;
    z-index: 2;
}
.rytchdv::before{
    content: '';
    position: absolute;
    top: 16px;
    left: 8px;
    width: 100%;
    height: 304px;
    background: #9b734f;
    z-index: -1;
}
.ryt_content p{
    font-size: 16px;
    background-color: #f9ebc6;
    color: #211d26;
    position: relative;
    padding: 62px 15px;
}
.whybutton{
    background: #f9ebc6;
    color: #211d26;
    text-decoration: none;
    font-size: 34px;
    font-weight: bold;
    padding: 4px 70px;
    margin-top: 40px;
    display: block;

}
.whybtdv{
    position: relative;
    z-index: 2;
}
.whybtdv::before{
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    width: 100%;
    height: 64px;
    background: #9b734f;
    z-index: -1;
}
.img_maindv{
    background-color: #9b734f;
    border: 1px solid #f9ebc6;
    margin-top: 20px;
}
.img_maindv ul{
    display: flex;
  flex-direction: row;
  column-gap: 14px;
  list-style: circle;
  margin-bottom: 0;
  border-bottom: 1px solid #f9ebc6;
}
.img_maindv ul li{
    font-size: 26px !important;
}
.img_chlddv{
    background: url(../src/images/whyus.jpg);
    width: 100%;
    height: 201px;
    background-size: cover;
    background-position: 100% 73%;
}
.ryt_content{
    width: 25%;
}
.aproach_head{
    position: relative;
    color: #211d26;
    font-family: "Archivo", sans-serif;
    font-style: normal;
    font-weight: 800;
}

.aproach_head::before{
    position: absolute;
    content: '';
    left: 0;
    bottom: -44px;
    width: 50%;
    height: 2px;
    background: #000;    
}
.approach_dv p{
    font-size: 12.2px;
    font-family: 'times new roman';
    padding-top: 73px;
}
.whowe_Head{
    font-family: 'times new roman';
    font-size: 20.9px;
}
.tm_lana{
    font-family: 'times new roman';
    font-size: 20.9px;
    color: #2a262a;
    padding: 0 30px;
}
.approach_img{
    background: url(./images/approach.jpg);
    width: 60%;
    height: 322px;
    background-size: cover;
    background-position: 100% 50%;
    margin-top: 45px;
}
.hero_icon{
    position: absolute;
  right: 0;
  background: #fff;
  padding: 0px 6px;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.heroiconbutn a button{
    padding: 7px 0;
}
.squad_img{
    background: url(./images/squad.jpg);
    height: 398px;
    width: 40%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 40%;
}
.comunity_img{
    background: url(./images/comunityimg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}