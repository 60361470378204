.policy-page {
    background-color: #f9f9f9; /* Light background for readability */
    color: #333; /* Dark text color for contrast */
    padding: 60px 20px; /* Padding for content spacing */
    text-align: left; /* Left align the text */
  }
  
  .container {
    max-width: 1140px; /* Limit the width for better readability */
    margin: 0 auto; /* Center the container */
  }
  
  h2 {
    font-size: 2rem; /* Large font size for the headline */
    margin-bottom: 20px; /* Spacing below the headline */
    color: #0047AB; /* Match the vibrant blue background of the CTA section */
  }
  
  p {
    font-size: 1.1rem; /* Readable font size for the paragraphs */
    margin-bottom: 20px; /* Spacing below paragraphs */
    line-height: 1.6; /* Line height for better readability */
  }
  
  h3 {
    font-size: 1.5rem; /* Font size for subheadings */
    margin-top: 30px; /* Spacing above subheadings */
    margin-bottom: 15px; /* Spacing below subheadings */
    color: #0047AB; /* Match the vibrant blue background of the CTA section */
  }
  
  ul {
    margin-bottom: 20px; /* Spacing below the list */
    padding-left: 20px; /* Padding for list indentation */
  }
  
  ul li {
    font-size: 1.1rem; /* Font size for list items */
    margin-bottom: 10px; /* Spacing between list items */
  }
  
  a {
    color: #0047AB; /* Link color to match the theme */
    text-decoration: none; /* Remove underline from links */
  }
  
  a:hover {
    text-decoration: underline; /* Underline on hover for links */
  }