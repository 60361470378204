.contact-section {
  max-width: 1600px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: #f4f4f4;
}

.contact-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-links a {
  font-size: 24px;
  margin: 0 10px;
  color: inherit; /* Adjust if needed */
}

.social-links a:hover {
  color: #007bff; /* Adjust if needed */
}