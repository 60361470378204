.cta-section {
    background-color: #0047AB; /* Vibrant blue background */
    color: white; /* White text for contrast */
    padding: 120px 0; /* Increased vertical padding for longer length */
    text-align: center; /* Center the text */
  }
  
  
  .cta-section h2 {
    font-size: 2.2rem; /* Larger font size for the headline */
    margin-bottom: 20px; /* Spacing below the headline */
  }
  
  .cta-section p {
    font-size: 1.2rem; /* Readable font size for the description */
    margin-bottom: 30px; /* Spacing below the description */
  }
  
  .btn-warning {
    background-color: #FFA500; /* Eye-catching orange for the button */
    color: white; /* White text on the button */
    padding: 15px 30px; /* Padding inside the button */
    border-radius: 5px; /* Rounded corners */
    text-decoration: none; /* Remove underline from the link */
    font-weight: bold; /* Bold font for emphasis */
    font-size: 1.1rem; /* Slightly larger font for the button */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .btn-warning:hover {
    background-color: #cc8400; /* Darker orange on hover */
  }
  