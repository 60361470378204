/* WhoWeAreForSection.css */
.who-we-are-for {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4rem 1rem;
  background-color: rgba(250, 250, 250, 0.9);
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.text-content {
  flex: 1;
  min-width: 300px; /* Ensures that the text content doesn't get too narrow */
}

.section-title {
  font-size: 2.2rem;
  color: #102A43;
  margin-bottom: 1rem;
  font-weight: 700;
}

.section-description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #334E68;
}

.image-content-whowearefor {
  flex: 1;
  min-width: 300px; /* Ensures that the image container doesn't get too narrow */
  height: 300px;
  background-image: url('images/helping_people.png'); /* Update with your image path */
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .text-content,
  .image-content-whowearefor {
    width: 100%;
    max-width: none;
    min-width: 0; /* Allows the content to fill the width on smaller screens */
  }

  .image-content-whowearefor {
    height: 200px; /* Adjust height on smaller screens if needed */
  }
}
