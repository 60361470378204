@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap'); /* Importing a bold font */

  .hero-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    background-image: url('images/Banner1.webp'); /* Path to your hero image */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .container {
    text-align: center;
    color: #e1d9d1; /* Adjust color to suit your design */
  }
  
  .animated-heading {
    font-family: 'Montserrat', sans-serif; /* This is the bold font */
    font-size: 1rem;
    font-weight: 800; /* 800 is equivalent to 'extra-bold' */
    animation: fadeInDown 2s ease-in-out forwards;
    margin: 0; /* Remove default margins to ensure centering */
    color: #e1d9d1;
    position: absolute;
    left: 18px;
    top: 15%;
  }
  
  .animated-subheading {
    font-size: 2.5rem;
    margin-top: 20px;
    animation: fadeInUp 2s ease-in-out forwards;
  }
  
  .animated-cta {
    margin-top: 40px;
    animation: fadeIn 2s 1s ease-in-out forwards;
  }
  
  /* Keyframes for animations */
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Additional styles for buttons etc. */
  .btn-primary {
    /* Your primary button styles */
    background-color: #007bff; /* Example primary color */
    border: none;
    padding: 10px 20px;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s;
  }
  
  .btn-primary:hover {
    background-color: #0056b3; /* Darken on hover for interaction feedback */
  }