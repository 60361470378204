/* Full Page Layout Styling */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f4f4f4; /* Light background for the entire page */
  }
  
  /* Background Image Section */
  .project1-bigpicture {
    
    background-image: url('./images/FinancialLiteracy.webp'); /* Replace with your image path */
    background-size: contain; /* Ensures the entire image fits inside the container */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image */
    height: 400px; /* Adjust height as necessary */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
    width: 100%; /* Ensures the div takes full width */
  }
  .project2-bigpicture {
    background-image: url('./images/DigitalHorizon2.webp'); /* Replace with your image path */
    background-size: contain; /* Ensures the entire image fits inside the container */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image */
    height: 400px; /* Adjust height as necessary */
    display: flex;
    align-items: center;
    position: relative; 
    justify-content: center;
    width: 100%; /* Ensures the div takes full width */
  }
  .project3-bigpicture {
    background-image: url('./images/BrightMindsRect.webp'); /* Replace with your image path */
    background-size: contain; /* Ensures the entire image fits inside the container */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image */
    height: 400px; /* Adjust height as necessary */
    display: flex;
    align-items: center;
    position: relative; 
    justify-content: center;
    width: 100%; /* Ensures the div takes full width */
  }
  .project4-bigpicture {
    background-image: url('./images/GreenWaveRect.webp'); /* Replace with your image path */
    background-size: contain; /* Ensures the entire image fits inside the container */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image */
    height: 400px; /* Adjust height as necessary */
    display: flex;
    align-items: center;
    position: relative; 
    justify-content: center;
    width: 100%; /* Ensures the div takes full width */
  }
  .project5-bigpicture {
    background-image: url('./images/GreenWaveRect.webp'); /* Replace with your image path */
    background-size: contain; /* Ensures the entire image fits inside the container */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image */
    height: 400px; /* Adjust height as necessary */
    display: flex;
    align-items: center;
    position: relative; 
    justify-content: center;
    width: 100%; /* Ensures the div takes full width */
  }
  .project6-bigpicture {
    background-image: url('./images/GreenWaveRect.webp'); /* Replace with your image path */
    background-size: contain; /* Ensures the entire image fits inside the container */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image */
    height: 400px; /* Adjust height as necessary */
    display: flex;
    align-items: center;
    position: relative; 
    justify-content: center;
    width: 100%; /* Ensures the div takes full width */
  }
  
  /* Main Project Container */
  .project-container {
    width: 100%; /* Full width */
    padding: 50px 5%; /* Generous padding with a percentage for responsiveness */
    margin-top: -100px; /* Pulling the container up to overlap the image */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    border-radius: 15px;
    position:absolute;
  }
  
  /* Project Details Styling */
  .project-details-cards {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .project-details-cards h1, h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .project-details-cards p {
    color: #555;
    margin-bottom: 18px;
    line-height: 1.7;
  }
  
  /* Table Styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 15px;
    text-align: left;
  }
  
  th {
    background-color: #007bff; /* Consistent with primary color theme */
    color: white;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .project1-bigpicture {
      height: 250px;
    }
  
    .project-container {
      padding: 30px 3%;
      margin-top: -50px;
    }
  
    .project-details-cards {
      padding: 20px;
    }
  
    table {
      font-size: 14px;
    }
  }
  
  /* Additional styling for HomeButton and ApplicationForm can go here */
  .application-form {
    background-color: #f7f7f7; /* Light grey background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    max-width: 600px;
    margin: auto; /* Center the form */
  }
  
  .application-form h2 {
    color: #333; /* Dark grey for the header */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .application-form form {
    display: grid;
    grid-gap: 15px;
  }
  
  .application-form label {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    color: #555; /* Slightly dark grey for text */
  }
  
  .application-form input,
  .application-form textarea {
    padding: 10px;
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .application-form .invalid-field {
    color: red; /* Red color for invalid fields */
    border-color: red; /* Red border for invalid fields */
  }
  
  .application-form button {
    background-color: #4CAF50; /* Green background for the button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .application-form button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .home-button {
    position: absolute; /* Position the button absolutely within the .project1-bigpicture */
    top: 10px; /* Distance from the top of the container */
    right: 10px; /* Distance from the right of the container */
    border: none; /* Removes the default border */
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    padding: 10px 15px; /* Padding around the icon */
    border-radius: 50%; /* Circular shape */
    cursor: pointer; /* Cursor changes to pointer on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    z-index: 1000;
  }
  
  .home-button:hover {
    background-color: rgba(255, 255, 255, 1); /* Solid white background on hover for emphasis */
  }
  
  .home-button:focus {
    outline: none; /* Removes the focus outline */
  }
  .home-button-link {
    text-decoration: none; /* Removes underlining of the link */
    color: inherit; /* Ensures the button inherits the text color */
    /* ... other styles if needed ... */
  }

  .submitted-page-failure-container {
    background-color: #ffebee; /* Light red for error background */
    border-left: 4px solid #f44336; /* Darker red border for emphasis */
    padding: 20px;
    max-width: 600px;
    margin: 30px auto; /* Center the container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  }
  
  .failure-heading {
    color: #d32f2f; /* Slightly darker red for the heading */
    margin-bottom: 10px;
  }
  
  .failure-message {
    color: #555; /* Dark grey for the message */
    line-height: 1.6;
  }
  
  /* Styles for additional content can be added here */
  .submitted-page-container {
    background-color: #e8f5e9; /* Light green for success background */
    border-left: 4px solid #4caf50; /* Darker green border for emphasis */
    padding: 20px;
    max-width: 600px;
    margin: 30px auto; /* Center the container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .success-heading {
    color: #2e7d32; /* Slightly darker green for the heading */
    margin-bottom: 10px;
  }
  
  .success-message {
    color: #555; /* Dark grey for the message */
    line-height: 1.6;
  }
  
  /* Styles for additional content can be added here */
  

  .project-details-cards h1,
.project-details-cards h3,
.project-details-cards h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif; /* Update with your preferred font */
    margin-bottom: 15px;
}

.project-details-cards p,
.project-details-cards li {
    font-family: Verdana, Geneva, Tahoma, sans-serif; /* Update with your preferred font */
    line-height: 1.6; /* Improved readability */
    margin-bottom: 10px;
}
/* , sans-serif */

.program-audiences {
  margin-bottom: 15px; /* Adjust the value as needed */
}