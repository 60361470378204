.community {
    background-color: #f9ebc6; /* White background for a clean, modern look */
    color: #333333; /* Dark grey text for contrast and readability */
    padding: 60px 0; /* More vertical spacing for a less cramped look */
  }
  .comun_head{
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #544013;
    font-size: 2.8rem;
    text-align: left;
  }
  .container {
    max-width: 1140px; /* Slightly narrower container for focus */
    margin: 0 auto; /* Center the container */
    padding: 0 15px; /* Less side padding for a more edge-to-edge feel */
  }
  
  .section-title {
    text-align: center; /* Center-align the title */
    font-size: 2.8rem; /* Bigger and bolder title */
    margin-bottom: 30px; /* More spacing below the title */
    font-weight: 600; /* Slightly heavier font weight */
  }
  
  .section-description {
    font-size: 1.2rem; /* Slightly larger font size */
    line-height: 1.8; /* More line spacing for readability */
    margin-bottom: 50px; /* More spacing below the paragraph */
    text-align: center; /* Center-aligned text for focus */
  }
  
  .logo-container {
    display: grid; /* Using grid layout for logos */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjusting column size */
    gap: 30px; /* Increased gap between grid items */
    align-items: center; /* Align logos vertically */
    justify-items: center; /* Center logos horizontally */
    padding: 0 5%; /* Padding on the sides */
  }
  
  .logo-container div {
    width: 100%; /* Full width of the grid column */
    height: 180px; /* Increased height for logos */
    background-size: contain; /* Adjust background size to contain */
    background-repeat: no-repeat; /* No repeat of the background image */
    background-position: center; /* Center the background image */
  }
  
  .community_imgdv{
    display: flex;
  }
  .community_imgdv img{
     width: 100%; 
  }
  .community_imgdv .imgdv{
    width: 50%;
  }
  .community_imgdv .imgdv_content{
    width: 50%;
    padding-left: 73px;
  }
  .community_imgdv .imgdv_content p{
    font-size: 17.4px;
    line-height: 22px;
    text-align: left;
    color: #2a262a;
    padding-top: 50px;
  }
  
  /* Example logos */
  .logo1 { background-image: url('./images/hdfc_bank.png'); }
  .logo2 { background-image: url('./images/google_logo.png'); }
  .logo3 { background-image: url('./images/JanmLogo.jpeg'); }
  .logo4 { background-image: url('./images/prateek_som.jpeg'); }
  .logo5 { background-image: url('./images/haryana_govt.jpeg'); }
  
  @media screen and (max-width: 768px) {
    .logo-container div {
      flex-basis: 45%; /* Adjust size for smaller screens */
    }
  }
  