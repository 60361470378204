/* Sidebar.css */
.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #333;
    color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    z-index: 1000;
  }
  
  .sidebar-toggle {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
    background: none;
    border: none;
    color: #fff;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 15px;
    text-decoration: none;
    font-size: 20px;
  }
  
  .sidebar a {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
  }
  
  .sidebar a:hover {
    color: #f39c12;
  }
  
  .sidebar.open {
    width: 250px;
  }
  