
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
/* General styles for the projects section */
.projects {
    background-color: #e8e8e8; /* Light gray background for the projects section */
    padding: 50px 0; /* Same padding as other sections for consistency */
    font-family: 'Lato', sans-serif; /* Consistent font family */
  }
  
  .container {
    max-width: 1140px;
    margin: auto;
    padding: 0 15px;
  }
  
  .section-title {
    color: #333;
    font-size: 2rem;
    margin-bottom: 30px; /* Added space below the section title */
    text-align: center; /* Centering the title for aesthetic purpose */
  }
  
  .project-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Space out project cards evenly */
    gap: 20px; /* Gap between cards for spacing */
  }
  
  .project-card {
    background-color: #fff9e9; /* White background for cards to stand out */
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);  */
    /* border-radius: 8px;  */
    overflow: hidden; /* Ensures nothing spills out of the card corners */
    width: calc(33.333% - 20px); /* Three cards per row with gap accounted for */
    margin-bottom: 20px; /* Margin at the bottom of each card */
    border: 5px solid #000;
    padding: 22px;
  }
  .project_flex{
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 490px;
  }
  
  .project-image {
    width: 100%;
    height: 180px; /* Fixed height for image */
    background-size: cover;
    background-position: center;
  }
  
  /* Specific project images - you will add the correct paths */
  .project-image1 { background-image: url('./images/FinancialLiteracy.webp'); }
  .project-image2 { background-image: url('./images/DigitalHorizon.webp'); }
  .project-image3 { background-image: url('./images/BrightMinds.webp'); }
  .project-image4 { background-image: url('./images/GreenWave.webp'); }
  .project-image5 { background-image: url('./images/SkillSprint.webp'); }
  .project-image6 { background-image: url('./images/CulturalCanvas.webp'); }
  /* ... and so on for each project image */
  
  .project-details {
    padding: 15px; /* Padding inside the card for spacing around text */
    color: #555; /* Slightly darker text for readability */
  }
  
  .project-details h3 {
    color: #544013; /* Darker color for titles for contrast */
    margin-bottom: 10px; /* Space below the project title */
  }
  .project-details p{
    color: #544013;
    margin-bottom: 0;
    font-size: 16px;
  }
  
  .view-details-button {
    background-color: #9b734f; /* Bright blue for the button to stand out */
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    /* border-radius: 4px; */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transition for button interaction */
    margin-top: 15px; /* Space above the button */
    text-transform: uppercase;
    font-family: "Archivo", sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  
  .view-details-button:hover {
    background-color: #ca9b72; /* Darker blue on hover for interaction */
  }
  
  /* Media query for responsiveness */
  @media (max-width: 1024px) {
    .project-card {
      width: calc(50% - 20px); /* Two cards per row on smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .project-card {
      width: 100%; /* Full width cards on very small screens */
    }
  }
  .slots-full {
    color: red;
    font-weight: bold;
    position: relative;
    display: inline-block;
  }
  
  .full-text {
    position: absolute;
    top: -5px;
    left: 25px;
    font-size: 14px;
    white-space: nowrap;
    transform: translateY(-50%);
  }

  .view-details-button:disabled {
    background-color: purple;
    color: white;
    cursor: not-allowed;
  }