@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
/* AboutUs.css */

@font-face {
  font-family: 'times new roman';
  src: local('times new roman'), url('../../fonts/times-new-roman.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}
.about-us-container {
  /* max-width: 1200px; */
  margin: auto;
  padding: 40px 0;
  background-color: #3e3029; /* Consider using a light, inviting color or gradient */
}
.separator {
  height: 2px;
  background: #ccc; /* Light gray line */
  width: 80%; /* Or adjust to your preference */
  margin: 20px auto; /* Center and provide space around */
}

.header-section {
  text-align: center;
  margin-bottom: 50px;
}

.header-section h1 {
  font-size: 6rem; /* Larger, more prominent font size */
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif; /* Consistent font family */
  color: #333;
}

.header-section p {
  font-size: 1.2rem;
  color: #666;
  margin-top: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif; /* Consistent font family */
}

.team-section h2 {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 30px;
  font-family: "Archivo Black", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
}

.team-members {
  display: flex;
  justify-content: center;
  margin-bottom: 30px; /* Space between team member rows */
  font-family: Verdana, Geneva, Tahoma, sans-serif; /* Consistent font family */
  flex-wrap: wrap;
  background-color: #f9ebc6;
  padding: 40px 0;
}

.team-member {
  text-align: center;
  width: 33.33%; /* Larger width for bigger images */
}

.member-image {
  border-radius: 50%;
  width: 250px; /* Increased size for images */
  height: 250px;
  background-size: cover;
  margin: 0 auto 20px;
}

.team-member h3 {
  font-size: 2.5rem; /* Larger font size for names */
  color: #211d26;
  font-family: 'times new roman';
}

.team-member p {
  font-size: 15px;
  color: #211d26;
  font-family: Verdana, Geneva, Tahoma, sans-serif; /* Consistent font family */
  padding: 0 20px; /* Padding for longer texts */
  font-family: 'times new roman';

}
.anuj-image, .abida-image, .jayantee-image, .prachi-image, .sheilza-image {

  background-size: cover; /* Ensure the image covers the area */
  border-radius: 50%; /* This makes the image circular */
  width: 270px; /* Adjust width as needed */
  height: 270px; /* Adjust height as needed */

}

.anuj-image {
  background-image: url('../../images/ajbhatiya.jpg');
  border: 5px solid #000;
  
}

.abida-image {
  background-image: url('./images/Abida_Nahid.jpeg');
  border: 5px solid #000;
}

.jayantee-image {
  background-image: url('./images/jayantee.jpeg');
  border: 5px solid #000;
}

.Aadit-image {
  background-image: url('./images/Aadit-image.jpeg');
  border: 5px solid #000;
}

.sheilza-image {
  background-image: url('./images/Sheilza_Bhatia.jpeg');
  border: 5px solid #000;
}
