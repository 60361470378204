.who-we-are {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
  }
  
  .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .section-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .text-content {
    flex-basis: 50%;
  }
  
  .section-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .section-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  .learn-more-link {
    color: #0066cc;
    text-decoration: none;
  }
  
  .image-content-whoweare {
    flex-basis: 50%;
    background-image: url('images/WhoWeAre.webp'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    height: 300px; /* Adjust as needed */
  }
  
  /* Media Query for responsiveness */
  @media (max-width: 768px) {
    .section-content {
      flex-direction: column;
    }
  
    .image-content-whoweare {
      width: 100%;
      order: -1; /* Image above the text on smaller screens */
      margin-bottom: 20px;
    }
  
    .text-content {
      width: 100%;
    }
  }
  