.circle-container {
  display: inline-block;
  position: relative;
  width: 80px;  /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-text {
  color: white;
  font-size: 12px; /* Adjust font size as needed */
  font-weight: bold;
  text-align: center;
  transform: scale(1); /* Adjust scale as needed */
  white-space: nowrap;
}
